import React from 'react'

const requestNotificationPermission = async () => {
  if (typeof Notification !== 'undefined') {
    if (Notification.permission === 'default') {
      return await Notification.requestPermission()
    }
    return Notification.permission
  } else {
    console.error('API de Notificações não suportada neste navegador.')
    return 'denied' // Retorna 'denied' se a API não estiver disponível
  }
}

const showNotification = async (title, body, url = null) => {
  const permission = await requestNotificationPermission()

  if (permission === 'granted') {
    const notification = new Notification(title, {
      body: body,
      icon: '/favicon64.ico',
    })

    if (url) {
      // Adiciona um evento de clique à notificação
      notification.onclick = () => {
        window.open(url, '_blank') // Abre o link em uma nova aba
      }
    }
  } else {
    console.error('Permissão para notificações não concedida.')
  }
}

// Componente vazio, pois não precisa renderizar nada
const NotificationComponent = () => null

export { NotificationComponent, showNotification }
