const language = require('../components/ChangeLanguage').getMessage('menuprincipal')

export default async function getMenuData() {
  return [
    {
      category: true,
      title: ' ',
    },
    // {
    //   title: language.menu_home_title,
    //   key: 'dashboards',
    //   icon: 'fe fe-home',
    //   url: '/',
    // },
    {
      title: language.menu_board,
      key: 'board',
      icon: 'ri-apps-2-line',
      roles: ['board'],
      children: [
        {
          title: language.menu_board_telemetry,
          key: 'telemetry',
          url: '/telemetry',
          icon: 'fe fe-bar-chart-2',
          roles: ['board'],
        },
        {
          title: language.menu_board_sellers,
          key: 'sellers',
          url: '/sellers',
          icon: 'fe fe-users',
          roles: ['board'],
        },
        {
          title: language.menu_board_recurrence,
          key: 'recurrence',
          url: '/recurrence',
          icon: 'fe fe-repeat',
          roles: ['board'],
        },
        {
          title: language.menu_board_manage_plans,
          key: 'manage-plans',
          url: '/manage-plans',
          icon: 'fe fe-book-open',
          roles: ['board'],
        },
      ],
    },
    {
      title: language.menu_support,
      key: 'support',
      icon: 'ri-spy-line',
      roles: ['board', 'support'],
      children: [
        {
          title: language.menu_support_checklist,
          key: 'checklist',
          url: '/support/checklist',
          icon: 'fe fe-user-check',
          roles: ['board', 'support'],
        },
        {
          title: language.menu_support_deleteleads,
          key: 'deleteleads',
          url: '/support/delete-leads',
          icon: 'fe fe-user-minus',
          roles: ['board', 'support'],
        },
        {
          title: language.menu_support_fixscraperleads,
          key: 'fixscraperleads',
          url: '/support/fix-scraper-numbers',
          icon: 'fe fe-instagram',
          roles: ['board', 'support'],
        },
        {
          title: language.menu_support_removeduplicates,
          key: 'removeduplicates',
          url: '/support/remove-duplicates',
          icon: 'fe fe-user-x',
          roles: ['board', 'support'],
        },
        {
          title: language.menu_support_clearRedriveScraper,
          key: 'clear-redrive-scraper',
          url: '/support/clear-redrive-scraper',
          icon: 'fe fe-trash-2',
          roles: ['board', 'support'],
        },
        {
          title: language.menu_support_teams,
          key: 'teams',
          url: '/support/teams',
          icon: 'fe fe-users',
          roles: ['board'],
        },
        {
          title: language.menu_support_redtech,
          key: 'redtech',
          icon: 'fe fe-code',
          roles: ['board', 'support'],
          children: [
            {
              title: language.menu_support_redtech_broadcastwhats,
              key: 'broadcastwhats',
              url: '/sending',
              icon: 'fa fa-whatsapp',
              roles: ['board', 'support'],
            },
            {
              title: language.menu_support_redtech_broadcastinsta,
              key: 'broadcastinsta',
              url: '/sending-instagram',
              icon: 'fe fe-instagram',
              roles: ['board', 'support'],
            },
            {
              title: language.menu_support_redtech_capturaInstagram,
              key: 'capturaInstagram',
              url: '/scrapers',
              icon: 'fe fe-instagram',
              roles: ['board', 'support'],
            },
            {
              title: language.menu_support_redtech_botcreator,
              key: 'botcreator',
              url: '/support/bot-creator',
              icon: 'fe fe-cpu',
              roles: ['board', 'support'],
            },
          ],
        },
        {
          title: language.menu_support_google,
          key: 'google',
          url: '/leads/google/config',
          icon: 'fe fe-code',
          roles: ['board', 'support', 'facebook'],
        },
        {
          title: language.menu_support_error_systems,
          key: 'erros',
          url: '/support/error-systems',
          icon: 'fe fe-code',
          roles: ['board'],
        },
      ],
    },
    {
      title: language.menu_messenger,
      key: 'messenger',
      url: '/messenger',
      icon: 'ri-message-2-line',
      roles: [
        'attendantbasic',
        'attendant',
        'basic',
        'admin',
        'pro',
        'pro2',
        'enterprise',
        'board',
        'seller',
        'partner',
        'support',
        'facebook',
      ],
    },
    {
      title: language.menu_crm,
      key: 'crm',
      url: '/crm',
      icon: 'ri-contacts-book-line',
      roles: [
        'attendant',
        'basic',
        'admin',
        'pro',
        'pro2',
        'enterprise',
        'board',
        'seller',
        'partner',
        'support',
        'facebook',
      ],
    },
    {
      title: language.menu_pipelines,
      key: 'pipelines',
      icon: 'ri-kanban-view',
      roles: [
        'attendant',
        'basic',
        'admin',
        'pro',
        'pro2',
        'enterprise',
        'board',
        'seller',
        'partner',
        'support',
        'facebook',
      ],
      children: [
        {
          title: language.menu_pipelines_pipeline,
          key: 'pipeline',
          url: '/crm/pipeline',
          icon: 'fe fe-target',
          roles: [
            'attendant',
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
          ],
        },
        {
          title: language.menu_messenger_pipeline,
          key: 'messenger-pipeline',
          url: '/messenger-pipeline',
          icon: 'fe fe-message-circle',
          roles: [
            'attendantbasic',
            'attendant',
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
          ],
        },
      ],
    },
    {
      title: language.menu_chatflow,
      key: 'chatflow',
      url: '/chatflow',
      icon: 'ri-robot-3-line',
      roles: [
        'basic',
        'pro',
        'pro2',
        'enterprise',
        'board',
        'seller',
        'partner',
        'support',
        'facebook',
        'attendant',
      ],
    },
    {
      title: language.menu_transmission,
      key: 'message',
      icon: 'ri-send-plane-line',
      roles: [
        'basic',
        'admin',
        'pro',
        'pro2',
        'enterprise',
        'board',
        'seller',
        'partner',
        'support',
        'attendant',
      ],
      children: [
        {
          title: language.menu_transmission_wppChatbot,
          key: 'bot',
          url: '/whatsapp/chatbot',
          icon: 'fa fa-whatsapp',
          roles: [
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
        },
        {
          title: language.menu_transmission_wppBusiness,
          key: 'whatsappbusinesschatbot',
          url: '/whatsapp-business/chatbot',
          icon: 'fa fa-whatsapp',
          roles: [
            'whatsapp-business',
            'admin',
            'basic',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
        },
        {
          title: language.menu_transmission_smsBot,
          key: 'bot-sms',
          url: '/sms/chatbot',
          icon: 'fas fa-sms',
          roles: [
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
        },
        {
          title: language.menu_transmission_linkedinBot,
          key: 'linkedinconnect',

          url: '/linkedin/connectbot',
          icon: 'fa fa-linkedin',
          roles: ['linkedin', 'admin', 'pro', 'enterprise', 'board', 'seller'],
        },
        {
          title: language.menu_transmission_history_whats,
          key: 'history',
          url: '/whatsapp/historico',
          icon: 'fa fa-history',
          roles: [
            'admin',
            'basic',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
        },
        {
          title: language.menu_transmission_history_whatsBusiness,
          key: 'whatsapp-business-history',
          url: '/whatsapp-business/historico',
          icon: 'fa fa-history',
          roles: [
            'whatsapp-business',
            'admin',
            'basic',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
        },
        // Esse link não estava levando a lugar algum. Retirado na task RA2-1077
        // {
        //   title: language.menu_transmission_history_insta,
        //   key: 'history-insta',
        //   url: '/instagram/historico',
        //   roles: ['pro', 'pro2', 'enterprise', 'board', 'seller', 'partner', 'support'],
        //   icon: 'fa fa-history',
        // },
        {
          title: language.menu_transmission_history_linkedin,
          key: 'linkedin-history',
          url: '/linkedin/historico',
          icon: 'fa fa-history',
          roles: [
            'linkedin',
            'admin',
            'pro',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
          ],
        },
        {
          title: language.menu_transmission_history_sms,
          key: 'history-sms',
          url: '/sms/historico',
          roles: [
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
          icon: 'fa fa-history',
        },
        {
          title: language.menu_transmission_linkGenerator,
          key: 'whatslink',
          url: '/whats-app-link',
          roles: [
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
          icon: 'fe fe-link',
        },
        {
          title: language.menu_transmission_heatNumber,
          key: 'heat-number',
          url: '/heat-number',
          roles: [
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
          icon: 'fa fa-fire',
        },
        {
          title: language.menu_transmission_copyGenerator,
          key: 'copy-generator',
          url: '/copy-generator',
          roles: [
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
          icon: 'fa fa-lightbulb',
        },
      ],
    },
    {
      title: language.menu_importLeads,
      key: 'importLeads',
      icon: 'ri-import-line',
      roles: [
        'basic',
        'admin',
        'pro',
        'pro2',
        'enterprise',
        'board',
        'seller',
        'partner',
        'support',
        'facebook',
        'attendant',
      ],
      children: [
        {
          title: language.menu_importLeads_whatsappGroups,
          key: 'whatsapp',
          url: '/leads/whatsapp',
          roles: [
            'admin',
            'basic',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
          icon: 'fa fa-whatsapp',
        },
        {
          title: language.menu_importLeads_whatsappContacts,
          key: 'whatsappcontacts',
          url: '/leads/whatsapp/contacts',
          roles: [
            'admin',
            'basic',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
          icon: 'fa fa-whatsapp',
        },
        {
          title: language.menu_importLeads_whatsappChats,
          key: 'whatsappchats',
          url: '/leads/whatsapp/chats-import',
          roles: [
            'admin',
            'basic',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
          icon: 'fa fa-whatsapp',
        },
        {
          title: language.menu_importLeads_instagramFollowers,
          key: 'instagram-a',
          url: '/leads/instagram',
          roles: [
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
          icon: 'fe fe-instagram',
        },
        {
          title: language.menu_importLeads_instagramLikes,
          key: 'instagram-b',
          url: '/leads/instagram/post',
          roles: [
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
          icon: 'fe fe-instagram',
        },
        {
          title: language.menu_importLeads_linkedin,
          key: 'linkedin',
          url: '/leads/linkedin',
          roles: [
            'admin',
            'enterprise',
            'pro',
            'board',
            'seller',
            'partner',
            'support',
            'attendant',
          ],
          icon: 'fe fe-linkedin',
        },
        {
          title: language.menu_importLeads_googleMaps,
          key: 'google',
          url: '/leads/google',
          roles: [
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
          icon: 'fa fa-google',
        },
        {
          title: language.menu_importLeads_bingMaps,
          key: 'bing',
          url: '/leads/bing',
          roles: [
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
          icon: 'fa fa-windows',
        },
        {
          title: language.menu_importLeads_companies,
          key: 'empresas-b',
          url: '/leads/cadastro-nacional',
          roles: [
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
          icon: 'fa fa-building',
        },
        {
          title: language.menu_importLeads_importCsv,
          key: 'import-csv',
          url: '/leads/csv',
          icon: 'fe fe-file-plus',
          roles: [
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
        },
      ],
    },
    {
      title: 'Relatório',
      key: 'report',
      icon: 'ri-file-chart-line',
      roles: [
        'attendant',
        'basic',
        'admin',
        'pro',
        'pro2',
        'enterprise',
        'board',
        'seller',
        'partner',
        'support',
        'facebook',
      ],
      children: [
        {
          title: 'Desempenho de Disparos',
          key: 'performance',
          url: '/report/performance',
          icon: 'fe fe-trending-up',
          roles: [
            // 'attendant',
            'basic',
            'admin',
            'pro',
            'pro2',
            'enterprise',
            'board',
            'seller',
            'partner',
            'support',
            'facebook',
            'attendant',
          ],
        },
      ],
    },
  ]
}
